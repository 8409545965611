import React, { useContext } from 'react';
import {Typography} from "@mui/material";
import Div from "@jumbo/shared/Div";
import { AppContext } from 'app/AppContext';
import { Link } from "react-router-dom";


const Footer = ({loginUserData}) => {
    const {companyName} = useContext(AppContext)

    return (
        <Div sx={{
            py: 2,
            px: {lg: 6, xs: 4},
            borderTop: 2,
            borderColor: 'divider',
            bgcolor: 'background.paper',
        }}>
            <Div sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Typography variant={"body1"} color={"text.primary"}>Global Dynamics Savings Group {companyName} ©  2023 {companyName} All rights reserves <a href="https://drive.google.com/file/d/1yBFNGQffyaSCEbXPW_62x5ZE2H8ydM6v/view" target='_blank'> Privacy | Security</a></Typography>
                {
                    loginUserData.userid > 500 && loginUserData.createdby === 'user'  ? (<Typography variant={"body1"} sx={{}} color={"text.primary"}>Powered By <a href="https://threearrowstech.com" target='_blank'><b>Three Arrows Tech</b></a></Typography>) : (<Typography variant={"body1"} sx={{}} color={"text.primary"}>Designed By <a href="https://marshallmt.com/" target='_blank'><b>Marshall Multimedia Technology</b></a></Typography>)
                }
                

            </Div>
        </Div>
    );
};

export default Footer;
