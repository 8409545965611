import React, { useState, useEffect } from 'react';
import JumboDemoCard from "@jumbo/components/JumboDemoCard";
import Box from "@mui/material/Box";
import {Alert, Grid} from "@mui/material";
import {Form, Formik} from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import SweetAlert from 'app/pages/components/mui/Alerts/SweetAlert';
import * as yup from "yup";
import JumboTextField from '@jumbo/components/JumboFormik/JumboTextField';
import Div from '@jumbo/shared/Div/Div';
import { transaction } from 'backendServices/ApiCalls';
import useJumboAuth from '@jumbo/hooks/useJumboAuth';


const validationSchema = yup.object({
  amount: yup
      .number('Enter investment amount')
      .required('Amount is required'),
  password: yup
      .string('Enter your password')
      .required('Password is required'),
  payoutaccount1: yup
  .string('Enter Coin Name')
  .required('Coin name is required'),
  payoutaccount2: yup
  .string('Enter Wallet Address')
  .required('Wallet address is required'),
});
const Payout = () => {
  const [alertData, setalertData] = React.useState({
    show:false,
    message:"",
    variant:"" 
  })
  const {...authOptions} = useJumboAuth()
  let userData = authOptions?.authUser?.data
  


  const onSubmitForm = (amount,payoutaccount1,payoutaccount2,password,setSubmitting,resetForm) => {
    let params = {
      amount,
      payoutaccount1,
      payoutaccount2,
      type: 'payout',
      status: 'Pending',
      details: 'Request payout of $'+amount,
      password,

  }
 



    transaction(params, (response) => {
      console.log("respons",response)
      
      if( response?.data?.status === "error"){
        setalertData({
          show:true,
          message:response?.data?.message,
          variant:"error"
      }) 
          setSubmitting(false)
      }else if(response?.data?.status === "success"){

        setalertData({
          show:true,
          message:response?.data?.message,
          variant:"success"
      })
          setSubmitting(false)
          resetForm();

      }
     
  }, (error) => {
      console.log(error?.response?.data);
  })
}

    return (
      <Grid container spacing={2}  alignItems="center" justifyContent="center" >
         {
            alertData.show && (<SweetAlert alertData={alertData} setalertData={setalertData} />)
        }
     
      <Grid item sm={8} >
        <JumboDemoCard  title={"Payout Form"}
                       wrapperSx={{backgroundColor: 'background.paper', pt: 0}}>

          <Alert severity='warning'>You have to complete 15 levels to get payout</Alert>
             {/* <Formik
                        validateOnChange={true}
                        initialValues={{
                            amount: '',
                            payoutaccount1: '',
                            payoutaccount2: '',
                            password: '',
                            random:userData?.walletbalance
                        }}
                        validationSchema={validationSchema}
                         onSubmit={(data, { setSubmitting, resetForm }) => {
              setSubmitting(true);
              onSubmitForm(data.amount,data.payoutaccount1,data.payoutaccount2,data.password,setSubmitting, resetForm);
            }}
                    >
                        {({isSubmitting}) => (
                            <Form style={{textAlign: 'left'}} noValidate autoComplete='off'>

            <Box 

                sx={{
                    display: 'flex',
                    flexDirection: 'column',                  
                    '& .MuiTextField-root': {width: '34ch'},
                }}
                alignItems="center"
            >
                                  
                                  
                                    <Div sx={{mt: 2,}}>
                                    <JumboTextField
                                        fullWidth
                                        name="random"
                                        label="Your Wallet Balance"
                                        type="number"
                                        value="124"
                                        disabled
                                    /></Div>
                                    <Div sx={{mt: 3,}}>
                                    <JumboTextField
                                        fullWidth
                                        name="amount"
                                        label="Enter Payout Amount"
                                        type="number"
                                    /></Div>
                                   <Div sx={{mt: 3,}}>
                                        <JumboTextField
                                        fullWidth
                                        name="payoutaccount1"
                                        label="Enter Coin Name"
                                        type="text"
                                    /></Div>
                                    <Div sx={{mt: 3,}}>
                                        <JumboTextField
                                        fullWidth
                                        name="payoutaccount2"
                                        label="Enter Wallet Address"
                                        type="text"
                                    /></Div>
                                   <Div sx={{mt: 3,mb:2}}>
                                        <JumboTextField
                                        fullWidth
                                        name="password"
                                        label="Enter Password"
                                        type="password"
                                    /></Div>

   



      
               

              
<LoadingButton
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    size="large"
                                    sx={{mb: 3}}
                                    loading={isSubmitting}
                                >Submit</LoadingButton>
            </Box>
            </Form>
             )}
             </Formik> */}
        </JumboDemoCard>
      </Grid>
      </Grid>
    );
};

export default Payout;








