import axios from 'axios';

const API_BASE_URL = 'https://threearrowstech.com/projects/gdsg/staging/backend_apis';


export function registerUser(params, callback, errorCallback) {
  axios.post(API_BASE_URL+'/api/user/register/', params)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}



export function submitManualPayment(params, callback, errorCallback) {
  axios.post(API_BASE_URL+'/api/user/submitmanualpayment/', params, {
    headers : {
      'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
    }
  
})
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function authenticate(service, callback, errorCallback) {
  axios.post(API_BASE_URL, service)
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}



export  function  authUserData(callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/user/userdata/'}`, '', {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
    
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export  function roidata(callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/user/roidata/'}`, '', {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
    
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}



export  function lasttransactions(callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/lastweektransactions/'}`, '', {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
    
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export  function referralusers(callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/user/referralusers/'}`, '', {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}



export  function investmentreport(callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/user/depositsummary/'}`, '', {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export  function referralbonusreport(callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/referralbonussummary/'}`, '', {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}



export  function userpayouts(callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/userpayouts/'}`, '', {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export  function unilevelbonusreport(callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/unilevelbonussummary/'}`, '', {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export  function faqdata(callback, errorCallback) {
  axios.post(`${API_BASE_URL}${'/api/getfaqs/'}`, '', {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export function transaction(params, callback, errorCallback) {
  axios.post(API_BASE_URL+'/api/transaction/', params,{
    headers : {
      'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
    }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}



export function submitnda(params, callback, errorCallback) {
  axios.post(API_BASE_URL+'/api/submitnda/', params,{
    headers : {
      'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
    }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export function invitation(params, callback, errorCallback) {
  axios.post(API_BASE_URL+'/api/sendinvitationlink/', params,{
    headers : {
      'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
    }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export  function adminwallet(callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/getadminwallet/'}`, '', {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}



export  function deployuser(investmentamount,callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/deployuser/'}`, 
  {
    investmentamount:investmentamount
  }
  , {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export  function getHierarchyData(callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/gethierarchy/'}`, '', {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export  function getBinaryTreeData(userrandomcode,callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/getbinarytree/'}`, {
    userrandomcode:userrandomcode
  }, {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export  function updateProfileData(formdata, callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/updateprofiledata/'}`, formdata, {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export  function updateProfilePicture(formdata, callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/updateprofilepicture/'}`, formdata, {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export  function updateProfilePassword(oldpassword,newpassword, callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/updatepassword/'}`, {
    oldpassword,
    newpassword
  }, {
      headers : {
        'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
      }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}


export  function requestPasswordReset(email, callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/forgetpassword/'}`, {
    email
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export  function investandeearning(callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/monthlyinvestandeearning/'}`, '', {
    headers : {
      'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
    }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

export  function getnotifications(callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/getnotifications/'}`, '', {
    headers : {
      'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
    }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}



export  function updatenotificationstatus(callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/updatenotificationstatus/'}`, '', {
    headers : {
      'AUTHORIZATION' : 'Bearer '+localStorage.getItem('token')
    }
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}



export  function passwordReset(email,password, callback, errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/resetpassword/'}`, {
    email,
    password
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}



export  function validateEmailToken(token,email, callback,errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/validateemailtoken/'}`, {
    token,
    email
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}



export  function verifyemailaccount(token,email, callback,errorCallback) {
  axios.post(`${API_BASE_URL}/${'api/verifyemailaccount/'}`, {
    token,
    email
  })
    .then(response => {
      if (callback) {
        callback(response);
      }
    })
    .catch(error => {
      if (errorCallback) {
        errorCallback(error);
      }
    });
}

